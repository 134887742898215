html body{
  background-color: #fff;
  font-family: "Roboto", sans-serif !important;
}
.line_1header{
  background-color: #f7f7f7;
  width: 100%;
}
.line_1header > div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.line_1header li{
  margin-right: 2vh;
}
.line_1header ul{
  display: flex;
  flex-direction: row;
  list-style: none;
  padding-left: 0;
  justify-content: center;
  align-items: center;
}
.line_2header{
  height: 120px;
  padding-top: 2vh;
}
.line_2header > div > div > div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.contact_header{
  list-style: none;
  margin-bottom: 0;
}
.contact_header p{
  margin: 0;
}
.condivheader{
  height: 100px;
}
#search_input{
  width: 80%;
  border-radius: 10px;
  height: 40px;
  border: 1px solid #999;
  padding-left: 1vh;
}
.line_3header{
  height: 54px;
  width: 100%;
  background-color: #ffd333;
}
.condivheader3{
  height: 54px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.listpageheader3{
  list-style: none;
  height: 54px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
}
.listpageheader3 > li{
  margin-right: 2vh;
}
.listpageheader3 a{
  color: #333;
  text-decoration: none;
  font-weight: 600;
}
.btn_line3_categ{
  background-color: #3D464D;
  color: #fff;
  width: 100%;
  border-radius: 7px;
  padding: 1vh;
  border: none;
}
.row_line3{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.condivheader3_1{
  width: 18%;
}
.slidecontainer{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 385px;
}
.menu_categ_slide{
  width: 20%;
  background-color: #3D464D;
  height: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.menu_categ_slide ul{
  list-style: none;
  padding-left: 1vh;
  margin-top: 2vh;
}
.menu_categ_slide li{
  margin-top: 1vh;
}
.menu_categ_slide a{
  color: #fff;
  font-weight: 400;
  text-transform: capitalize;
  text-decoration: none;
}
.image_slide{
  width: 82%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5vh;
}
#carouselExampleControls{
  width: 100%;
  text-align: center;
}
.beneficios_home{
  margin-top: 2vh;
}
.beneficios_home section{
  border: 1px solid #F0F0F0;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}
.beneficios_home section > i{
  font-size: 35px;
  width: 20%;
  text-align: center;
}

.beneficios_home section > div{
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.beneficios_home section > div > h2{
  font-size: 22px;
  margin: 0;
}
.beneficios_home section > div > p{
  margin: 0;
  color: #81878C;
}
.beneficios_home > div >div{
  padding-left: 2px;
  padding-right: 2px;
}
.title_vitrine h3{
  font-size: 18px;
}
.title_vitrine{
  margin-top: 4vh;
}
.btn_slide_vitrine{
  width: 35px;
  height: 35px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container_btn_slide_vitrine{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 2vh;
}
.card_prod{
  border: 1px solid #F0F0F0;
  transition: 0.6;
  padding-bottom: 1vh;
  background-color: #fff;
  height: auto;
  min-width: 266px;
  margin-right: 0.5vh;
}
.card_prod:hover{
  border: 1px solid #FFD333;
  cursor: pointer;
}
.card_prod img{
  width: 228px;
  height: 228px;
}
.card_prod h1{
  font-size: 16px;
  text-align: justify;
  font-weight: normal;
  color: #81878C;
}
.card_prod p{
  font-size: 14px;
  color: #81878C;
  text-align: center;
  margin-bottom: 5px;
}
.btn_consult{
  background-color: transparent;
  border: none;
  font-weight: 600;
  margin-left: 1vh;
  color: #000;
  font-size: 18px;
  transition: 0.6s;
}
.btn_consult:hover{
  color: #3483fa;
  text-decoration: underline;
}
.container_images{
  width: 100%;
  text-align: center;
}
.boxbannerhome{
  background-image: url('componentes/images/banner-1.jpg');
  height: 170px;
  margin-top: 4vh;
  margin-bottom: 4vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.boxbannerhome div{
  text-align: center;
}
.boxbannerhome button{
  border: none;
  background-color: #FFD333;
  color: #505050;
  font-weight: 600;
  padding: 1vh;
  border-radius: 5px;
}
.card_box_categ_vitrine{
 margin: 0;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2vh;
}
.card_box_categ_vitrine img{
  width: 100px;
  height: 100px;
}
.textcontentcategshow{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 2vh !important;
}
.textcontentcategshow h1{
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
  color: #333;
}
.textcontentcategshow ul {
  list-style: none;
  padding: 0px;
}
.content_geral_gale{
  margin-top: 2vh;
  margin-bottom: 1vh;
}
footer ul{
  list-style: none;
  padding: 0;
}

footer li a{
  text-decoration: none;
  color: #333;
}

.context1footer p {
  margin: 0;
  margin-top: 1vh;
  margin-bottom: 1vh;
}
.listcontenttex1foo > li{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.listcontenttex1foo i{
  margin-right: 1vh;
}
.title_loja_footer{
  font-size: 18px;
  margin-bottom: 2vh;
}
.subfooter{
  border-top: 1px solid #F0F0F0;
}
.btnor{
  background-color: #FFD333;
  border: none;
  padding:2vh;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 20px;
}
.btncomprawhats{
  background-color: transparent;
  border: 2px solid #004d28;
  padding:2vh;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 20px;
  color: #004d28;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 1vh;
}
.btncomprawhats i{
  margin-right: 1vh;
}
.container_tab{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
}
.btntab{
  padding: 2vh;
  background-color: transparent;
  border: none;
  font-size: 18px;
  font-weight: 600;
}
.content_desc{
  width: 100%;
  border: 2px solid #F0F0F0;
  padding: 2vh;
}
.content_desc p{
  font-size: 18px;
}
.container_login{
  width: 30%;
  background-color: #fff;
  padding: 2vh;
  border-radius: 5px;
}
.container_p_login{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container_padmin{
  height: 80vh;
  justify-content: center !important;
  align-items: center !important;
}
.modalvm{
  width: 100%;
  height: 100vh;
  background-color: rgba(000,000,000, 0.8);
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}
.modalvmcontent{
  width: 60%;
  border-radius: 10px;
  padding: 2vh;
  background-color: #fff;
  max-height: 80vh;
  overflow-y: auto;
}
.container_Addcateg{
  background-color: #999;
  border-radius: 10px;
  padding:2vh;
  margin-top:2vh;
  margin-bottom:2vh;
}
.listcategmodal{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f2f2;
  padding:2vh;
  border-radius: 5px;
  margin-top:2vh;
  flex-wrap: wrap;
}
.listcategmodal p {
  text-align: center;
}
.modal_image_preview{
  width: 100%;
  height: 100vh;
  background-color: rgba(000,000,000, 0.8);
  position: fixed;
  top: 0;
  left: 0;

  justify-content: center;
  align-items: center;
  z-index: 9999999;
}
.modal_image_prevew_content{
  width: 60%;
  padding: 2vh;
  border-radius: 5px;
  background-color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contentbtnmodalimgpreview{
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  align-items: center;
}
.img_cad_galeria{
  height: 60px;
}
.container_image_galeriacad{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #d9d9d9;
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  padding: 1vh;
  border-radius: 5px;
  margin-left: 1vh;
}
.slidegalericad{
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.btn_remove_img_cad{
  margin-bottom: 1vh;
}
.slide_produtos_vitrine{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
  scroll-behavior: smooth !important;
  flex-wrap: nowrap !important;
}
.modal_add_slide{
  width: 100%;
  height: 100vh;
  background-color: rgba(000,000,000, 0.8);
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}
.content_add_slide{
  width: 60%;
  border-radius: 5px;
  background-color: #fff;
  padding: 2vh;
}
.container_geral_slide{
  margin-top: 2vh;
}
.carousel-item img{
  width: 80% !important;
}
.container_categ_more_info{
  width: 100%;
  margin-top: 2vh;
}
.containersubcateg{
  display: flex;
  flex-direction: row;
  padding: 2vh;
  margin-top: 2vh;
}
.divrigthsubs{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 2vh;
}
.dropcateghome{
  inset: 0px auto auto 2% !important;
  width: 400px;
}

.subcateg_container{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.subcateg_img_content{
  width: 30%;
}

.subcateg_text_content{
  width: 70%;
  display: flex;
  flex-direction: column;
}
.subcateg_text_content b{
  text-transform: capitalize;
}
.btncateghome{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.btncateghome button{
  background-color: transparent;
  border: none;
  color: #fff;
}
.categs_container{
  width: 80%;
  border: 2px solid #f0f0f0;
  padding: 2vh;
}
.categs_container a{
  text-decoration: none !important;
  color: #3d464d;
}
.categs_container h2{
  font-size: 20px;
  font-weight: 700;
  color: #3d464d;
}
.cardpagecateg{
  border: none !important;
  background-color: transparent !important;
}
.title_prod_categ{
  font-size: 16px;
  font-weight: 400;
}
.disp_prod_categ{
  color: #28a745;
}
.container_prods_categorias{
  width: 100%;
  border: 2px solid #f0f0f0;
  padding: 2vh;
}
.container_avalia_categ{
  color: #FFD333;
}
.galeria_de_produtos{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0vh;
  margin-bottom: 1vh;
}
.galeria_de_produtos img{
  width: 75px;
  height: auto;
  border: 2px solid #F0F0F0;
  transition: 0.6s;
  cursor: pointer;
}
.imgativa{
  border: 2px solid #FFD333 !important;
}
.galeria_de_produtos img:hover{
  border: 1px solid #FFD333;
}
.slide_product{
  border: 2px solid #f0f0f0;
  padding-top: 2vh;
}
.btn_zoom_prod{
  width: 46px;
  height: 46px;
  border: none;
  background-color: transparent;
  transition: 0.6s;

  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  font-size: 25px;
  color: #81878C;

  position: absolute;
  z-index: 99999999999;
  margin-left: 36% !important;
}
.btntabac{
  border-bottom: 2px solid #ffd333;
}
.slideprodrec{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.slideprodrec > div{
  margin-right: 2vh;
}
.card_pecas_destaque{
  
}
.container_imagem_categoria{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.container_imagem_categoria img{
  width: 60px;
  height: auto;
  border-radius: 50%;
  margin-left: -4vh;
  -webkit-box-shadow: 0px 0px 12px -6px rgba(0,0,0,0.61);
-moz-box-shadow: 0px 0px 12px -6px rgba(0,0,0,0.61);
box-shadow: 0px 0px 12px -6px rgba(0,0,0,0.61);
}
.titulo_categ_p_home{
  transition: 0.6s;
  text-decoration: none;
}
.titulo_categ_p_home:hover{
  text-decoration: underline;
  font-size: 20px !important;
}
.content_garantia{
  border: 2px solid #F0F0F0;
  padding: 2vh;
}
.container_quem_somos{
  background-image: url("https://i.imgur.com/cbMmsCl.jpeg");
  background-repeat: no-repeat;
  padding-top: 4vh;
}
.content_quem_somos{
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 5vh;
  padding-top: 2vh;
}
.content_quem_somos section{
  text-align: center;
}
.home_categ_show{
  background-color: #f9f9f9;
  margin-top: 2vh;
  padding-top: 1vh;
}
.btn-save-float{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: fixed;
  right: 2vh;
  bottom: 2vh;
  border:none;
  background-color: #ffd333;
  font-size: 25px;
  color: #fff;
  transition: 0.6s;
}
.btn-save-float:hover{
  background-color: #0A58CA;
}
.slider-container {
  width: 80%;
  margin: auto;
}

.slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px; /* Ajuste conforme necessário */
  min-width: 100%;
  position: relative !important;
}

.slide > div:nth-child(3){
  width: 20px !important;
  height: 20px !important;
  background-color: red !important;
  z-index: 999999999 !important;
  position: fixed !important;
}
.slide_vm{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.react-image-magnify-portal {
  position: absolute !important;
  width: 25px !important;
  height: 25px !important;
}
.btn-control-slide{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  font-size: 28px;
}
.container_btn_slide{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.btn-prev-slide{
  position: absolute;
  margin-top: 55vh;
  margin-left: -5vh;
}

.btn-prox-slide{
  position: absolute;
  margin-left: 480px;
  margin-top: 55vh;
}
figure {
  width: 100%;
  background-repeat: no-repeat;
  background-size: 150%;
  cursor: zoom-in;
}

figure:hover img {
  opacity: 0;
}

figure > img {
  display: block;
  width: 100%;
  pointer-events: none;
}
.searchResult{
  background-color: #F0F0F0;
  padding: 2vh;
  width: 30%;
  position: absolute;
  margin-top: 20vh;
  border-radius: 10px;
  max-height: 300px;
  overflow-x: auto;
  z-index: 9999999;
}
.container_prod_search h2{
  font-size: 16px;
}
.container_prod_search{
  margin-top: 2vh;
  border-bottom: 1px solid #ffd333;
}
.closeSearch{
  border: none;
  background-color: transparent;
  float: right;
}
.card_pecas_destaque2{
  width: 220px !important;
  min-width: 220px;
  border: 1px solid #F0F0F0;
  transition: 0.6;
  padding-bottom: 1vh;
  background-color: #fff;
  height: auto;
  margin-right: 0.5vh;
}
.card_pecas_destaque2 h1{
  font-size: 16px;
  margin-left: 1vh;
}
.pecasdest2{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.card_pecas_destaque{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.btn_detail{
  border: none;
  background-color: #ffd333;
  color: #000;
  font-size: 18px;
  padding: 2vh;
  border-radius: 10px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.btnfaleconosco{
  background-color: transparent;
  border: none;
  font-weight: bold;
  float: right;
  cursor: pointer;
}
.btnfaleconosco i{
 font-size: 20px;
}
.row_line3 > section{
  display: flex;
  width: 80%;
}
.btnfaleconhover{
  display: none;
  background-color: #ffff;
  border-radius: 10px;
  position: absolute;
  padding: 2vh;
  z-index: 99999999;
}
.confale{
  margin-top: 2vh;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 1vh;
}
.textcontentcategshow a{
  color: #333;
  text-decoration: none;
}
.textcontentcategshow a:hover{
  text-decoration: underline;
}
.contentBtnOrMob{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .line_3header{
    display: none;
  }
  .menu_categ_slide{
    display: none;
  }
  .image_slide{
    width: 100%;
  }
  .content_geral_header{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .content_1_header{
    width: 50%;
  }
  .content_2_header{
    width: 50%;
    text-align: right;
  }
  .dropdown-menu[data-bs-popper]{
    right: 0px !important;
  }
  .line_1header{
    display: none;
  }
  .infoproduct{
    width: 95% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 1vh;
    -webkit-box-shadow: 0px 0px 12px -3px rgba(0,0,0,0.30);
    -moz-box-shadow: 0px 0px 12px -3px rgba(0,0,0,0.30);
    box-shadow: 0px 0px 12px -3px rgba(0,0,0,0.30);
    margin-top: 4vh;
    border-radius: 10px;
    min-height: auto !important;
  }
  .tagproductnew{
    padding: 1vh;
    background-color: #0A58CA;
    margin-top: 2vh !important;
    margin-bottom: 2vh !important;
    border-radius: 5px;
    color: #fff;
    text-transform: capitalize;
  }
  .btnor{
    width: 80% !important;
  }
  .contentBtnOrMob{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btntab{
    font-size: 22px !important;
  }
  .content_desc p{
    font-size: 20px !important;
  }
  .line_2header{
    padding-top: 0px;
  }
  .galeria_de_produtos{
    overflow-y: scroll;
  }
  .galeria_de_produtos img{
    margin-right: 2vh;
  }
  .btn-prox-slide{
    margin-left: 32vh;
    margin-top: 50vh;
  }
  .btn-prev-slide{
    margin-top: 50vh;
  }
  .breadmobile{
    display: flex;
    flex-direction: column;
  }
  .title_loja_footer{
    text-align: center;
  }
  .context1footer p{
    text-align: center;
  }
  .subfooter{
    background-color: #ffd333;
    color: #fff;
    text-align: center;
  }
  .ytmobile h2{
    font-size: 20px;
    margin-top: 2vh;
  }
  .ytmobile{
    background-color: #f7f7f7;
    text-align: center;
  }
  .searchResult{
    margin-top: 0px;
    width: 100%;
    max-height: fit-content;
  }
  .card_box_categ_vitrine{
    border-bottom: 1px solid #ffd333;
  }
  .container_btn_slide_vitrine{
    display: none;
  }
  .card_prod{
    width: 60%;
  }
  .slide_produtos_vitrine{
    overflow-x: scroll;
  }
  .card_pecas_destaque{
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .card_pecas_destaque2{
    width: 48% !important;
    min-width: 40%;
  }
  .pecasdest2{
    margin-bottom: 2vh;
  }
  .slideprodrec{
    overflow-x: auto;
  }
  .beneficios_home{
    display: none;
  }
}