.containerModalOr{
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 999999;
    background-color: rgba(000, 000, 000, 0.8);
    top: 0;
    left: 0;


    justify-content: center;
    align-items: center;
}

.itemgaleria{
    height: 80px;
    width: auto;
}

.contentModalOr{
    width: 60%;
    padding: 2vh;
    border-radius: 10px;
    background-color: #fff;
}

.contentModalOr h1{
    font-size: 20px;
}

.contentModalOr h2{
    font-size: 16px;
}

.carousel-control-next > span{
    background-color: rgba(000,000,000, 0.8);
    padding: 2vh !important;
    border-radius: 10px;
  }
  .carousel-control-prev > span{
    background-color: rgba(000,000,000, 0.8);
    padding: 2vh !important;
    border-radius: 10px;
  }
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
@media only screen and (max-width: 600px) {
    .contentModalOr{
        width: 96%;
    }
    .contentModalOr > div > div{
        margin-bottom: 2vh;
    }
    .containerModalOr{
        align-items: flex-end;
    }
    .contentModalOr{
        border-radius: 0px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        animation: fadeInUp 1s ease-out;
    }
    .btnor2{
        width: 100% !important;
        border-radius: 0px !important;
    }
    .btnor1{
        width: 100% !important;
        border-radius: 0px !important;
    }
}